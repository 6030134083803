<template>
  <div class="lawsdetails" v-loading="boxloading">
    <!-- 头部 -->
    <app-header @getHeight="getHeight" :phone="phone" />

    <div class="max_width min_width min_height">
      <div
        class="flex align_center padtb_20"
        :style="{ 'margin-top': offsetHeight + 'px' }"
      >
        <div class="f838 size_14">当前位置：</div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>

          <el-breadcrumb-item :to="{ path: '/lawsregulations' }"
            >政策法规</el-breadcrumb-item
          >
          <el-breadcrumb-item :to="{ path: '/declaration' }"
            >今日申报</el-breadcrumb-item
          >
          <el-breadcrumb-item class="d8f">详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <section class="flex align_start jus_content marb_30">
        <!-- 左边 -->
        <div class="width_30 pad_30 bgfff">
          <div>
            <div class="border_title" style="font-size: 16px">最新发布</div>

            <div class="flex align_center mart_20">
              <div class="width laws_details_search">
                <el-input placeholder="请输入关键词" v-model="keyword">
                  <i
                    slot="suffix"
                    class="el-icon-search"
                    @click="searchData"
                  ></i>
                </el-input>
              </div>
            </div>
          </div>

          <!-- 列表 -->
          <div class="mart_30">
            <div
              class="lawsdetails_infinite"
              :class="{
                flex: policyList.length == 0,
                align_center: policyList.length == 0,
                jus_center: policyList.length == 0,
              }"
            >
              <div
                class="infinite-list-wrapper"
                style="overflow: auto; height: 100%"
                v-if="policyList.length > 0"
              >
                <ul
                  class="list"
                  v-infinite-scroll="infiniteData"
                  infinite-scroll-disabled="disabled"
                  :infinite-scroll-immediate="false"
                >
                  <li
                    v-for="(item, index) in policyList"
                    :key="index"
                    class="list-item padb_15 border_bottom mart_15"
                    :style="{ 'margin-top': index == 0 ? '0px' : '' }"
                    @click="setInfo(item.id)"
                  >
                    <div
                      class="size_18 ttt text_line2 font cursor"
                      :title="item.title"
                      :style="{ color: qid == item.id ? '#ff7129' : '' }"
                    >
                      {{ item.title }}
                    </div>
                    <div style="display: flex; justify-content: space-between">
                      <div class="c61 size_14 mart_20 time_font mart_10 weight">
                        {{ item.source }}
                      </div>
                      <div
                        class="c61 size_14 mart_20 time_font mart_10"
                        style="color: #ffa820"
                      >
                        剩余：{{ item.day }}
                      </div>
                    </div>

                    <div class="c61 size_14 mart_20 time_font mart_10">
                      发布时间：{{ item.createTime }}
                    </div>
                  </li>
                </ul>
                <p v-if="loading" class="text_center c61 mart_10">加载中...</p>
                <p v-if="noMore" class="text_center c61 mart_10">没有更多了</p>
              </div>

              <el-empty
                description="暂无数据"
                v-if="empty"
                :image-size="250"
              ></el-empty>
            </div>
          </div>
        </div>

        <!-- 右边 -->
        <div class="width_68 bgfff lawsdetails_pad">
          <div class="flex column align_center" style="min-height: 860px">
            <div class="size_20 weight" style="margin-left: 20px">
              {{ info.title }}
            </div>
            <div class="mart_20 flex align_center jus_center">
              <div class="size_16 marr_40 ttt weight">
                {{ info.source }}
              </div>
              <div class="size_16 marr_40" style="color: #808080">
                发布时间：{{ info.createTime }}
              </div>

              <div class="size_16 marr_40" style="color: #808080">
                申报时间：{{ info.startTime }}至{{ info.endTime }}
              </div>
            </div>

            <div
              class="mart_20"
              style="
                display: flex;
                justify-content: space-between;
                width: 780px;
                height: 50px;
                background-color: #cce7ff;
                line-height: 50px;
              "
            >
              <!-- v-if="info.day != 0" -->
              <div style="margin-left: 20px" class="flex">
                <img
                  style="width: 24px; height: 30px; margin-top: 10px"
                  src="../../assets/image/declareicon.png"
                  alt=""
                />
                <div class="weight ttt size_20" style="margin-left: 20px">
                  申报入口剩余：{{ info.day }}天
                </div>
                <!-- v-if="info.type === 1" -->
              </div>
              <el-button
                type="text"
                style="margin-right: 30px; color: #ff7129"
                v-if="info.type === 1"
                @click="applyFor()"
              >
                点击申请>>></el-button
              >
              <el-button
                type="text"
                style="margin-right: 30px; color: #ff7129"
                v-if="info.type === 2"
                @click="lookmessage()"
                >查看联系方式>>></el-button
              >

              <!-- <div class="marr_30"></div> -->
              <!-- <div>查看联系方式>>></div> -->
            </div>

            <div
              class="mart_20 size_14 fnf line_heihgt30"
              v-html="info.content"
            ></div>
          </div>
        </div>
      </section>
    </div>

    <el-dialog title="立即申报" :visible.sync="dialogFormVisible">
      <el-form :model="from">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input
            v-model="from.name"
            style="width: 200px"
            autocomplete="off"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input
            v-model="from.phone"
            placeholder="请输入电话"
            style="width: 200px"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="getadd()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="线下申报" :visible.sync="dialogFormVisibles">
      <el-form :model="look">
        <el-form-item label="姓名" :label-width="formLabelWidth">
          <el-input
            v-model="look.name"
            style="width: 200px"
            autocomplete="off"
            placeholder="请输入姓名"
            readonly="readonly"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="电话" :label-width="formLabelWidth">
          <el-input
            v-model="look.phone"
            placeholder="请输入电话"
            style="width: 200px"
            autocomplete="off"
            readonly="readonly"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibles = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisibles = false">确 定</el-button>
      </div> -->
    </el-dialog>

    <el-backtop target=".lawsdetails">
      <i class="el-icon-caret-top"></i>
    </el-backtop>

    <!-- 尾部 -->
    <app-footer @phone="getPhone" />
  </div>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
  data() {
    return {
      keyword: "",
      loading: false,
      boxloading: false,
      policyList: [],
      page: 1,
      limit: 10,
      dialogFormVisible: false, //申请
      dialogFormVisibles: false, //查看
      formLabelWidth: "60px",
      // 总数
      count: 0,
      // 详情
      info: {},
      arr: [],
      qid: "",
      empty: false,
      offsetHeight: 0,
      phone: "",
      particulars: [],
      sta: 0,
      from: {
        name: "",
        phone: "",
      },
      look: {
        name: "",
        phone: "",
      },
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  computed: {
    noMore() {
      return this.arr.length < this.limit;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    // 认领
    applyFor() {
      this.authentication(2);
    },
    //查看
    lookmessage() {
      this.authentication(1);
    },
    // 认证
    authentication(item) {
      // 1是查看  2是申请
      const index = localStorage.logintype;
      if (index == 2 || index == 3) {
        this.$message({
          type: "warning",
          message: "该产品只限企业用户申请",
        });
      } else {
        this.boxloading = true;
        let data = {
          token: localStorage.eleToken,
        };
        console.log(index, "index");
        console.log(data, "data");
        this.$get("/enterprise_user_status", data).then((res) => {
          console.log(res, "res");
          this.boxloading = false;
          if (res.status === 1) {
            if (item === 1) {
              this.dialogFormVisibles = true;
            } else if (item === 2) {
              this.dialogFormVisible = true;
            }
          } else if (res.status === 2) {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            location.href = "https://sso.smeqh.cn/realname/index";
          }
        });
      }
    },

    //添加
    getadd() {
      console.log(this.from.name, this.from.phone, this.qid);
      let data = {
        token: localStorage.eleToken,
        declares_id: this.qid,
        contact: this.from.name,
        mobile: this.from.phone,
      };
      console.log(data, "data");
      this.$post("/declares_user_add", data).then((res) => {
        console.log(res, "res");
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.dialogFormVisible = false;
      });
    },
    getPhone(phone) {
      this.phone = phone;
    },
    // 获取头部高度
    getHeight(data) {
      this.offsetHeight = data;
    },
    searchData() {
      this.page = 1;
      this.policyList = [];

      this.getPolicyList();
    },
    // 个人申报列表
    getPolicyList() {
      let data = {
        page: this.page,
        limit: this.limit,
        keyword: this.keyword,
        status: this.sta,
      };

      this.$post("/declares_list", data).then((res) => {
        this.arr = res.result ? res.result : [];

        if (res.result) {
          this.policyList = res.result;
          console.log(this.policyList, "policyList");
          this.count = res.count;
          this.empty = false;

          this.getInfoPort();
        } else {
          if (this.policyList.length > 0) {
            this.loading = false;
            return;
          }

          this.policyList = [];
          this.empty = true;
        }

        this.loading = false;
      });
    },
    // 滚动分页
    infiniteData() {
      this.loading = true;

      this.page += 1;

      this.getPolicyList();
    },
    // 详情
    getInfoPort() {
      console.log(this.qid);
      let data = { id: this.qid };
      this.$get("/declares_info", data).then((res) => {
        console.log(res, "res");
        this.look.name = res.result.contact;
        this.look.phone = res.result.mobile;
        this.info = res.result;
      });
    },

    // 点击左边展示详情
    setInfo(id) {
      this.qid = id;
      this.getInfoPort();
    },
  },
  mounted() {
    this.qid = this.$route.query.ids;
    this.sta = this.$route.query.sta;
    this.getPolicyList();
  },
};
</script>

<style scoped>
@import url("./declarationdetails.css");
</style>
